import Config from "../Config"
export default function Komfortplus() {
    const OneHourse = 140;
    const threeHourse = 350;
    const foureHourse = 400;
    const twelveHourse = 900;
    const night = 500;
    return (
        <Config
            zal='Комфорт +'
            proc='i5 12600KF'
            videocard='RTX 4060 TI'
            ssd='3 TB'
            oper='32 GB'
            monik='Samsung 24.5 244 Ghz'
            keyboard='Zet Gaming'
            mouse='Logitech G 403'
            headPhone='HyperX cloud Alpha'
            OneHourse= {OneHourse}
            threeHourse={threeHourse}
            foureHourse={foureHourse}
            twelveHourse={twelveHourse}
            night={night}
            threeOne={Math.floor(threeHourse / 3)}
            foureOne={Math.floor(foureHourse / 4)}
            twelveOne={Math.floor(twelveHourse / 12)}
            nightOne={Math.floor(night / 11)}
        />
    )
}