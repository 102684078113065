import Config from "../Config"
export default function Komfort() {
    const OneHourse = 120;
    const threeHourse = 300;
    const foureHourse = 350;
    const twelveHourse = 800;
    const night = 450;
    return (
        <Config
            zal='Комфорт'
            proc='i5 9600KF'
            videocard='RTX 2070 Super'
            ssd='2 TB'
            oper='32 GB'
            monik='Benq 24.5 244 Ghz'
            keyboard='Zet Gaming'
            mouse='Logitech G 102'
            headPhone='HyperX cloud 2'
            OneHourse= {OneHourse}
            threeHourse={threeHourse}
            foureHourse={foureHourse}
            twelveHourse={twelveHourse}
            night={night}
            threeOne={Math.floor(threeHourse / 3)}
            foureOne={Math.floor(foureHourse / 4)}
            twelveOne={Math.floor(twelveHourse / 12)}
            nightOne={Math.floor(night / 11)}
        />
    )
}